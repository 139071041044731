import axios from 'axios';
import { LBA_API } from './constants';

const coordinates = process.env.REACT_APP_SITE_COORDINATES?.split(',');

export const fetchSensorHealth = async () => {
  try {
    const url = LBA_API.sensorHealth;
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
      params: {
        user: process.env.REACT_APP_USERNAME,
        pass: process.env.REACT_APP_PASSWORD,
        siteId: process.env.REACT_APP_SITE_ID,
      },
    };
    const data = await axios.get(url, config);
    return data;
  } catch (error) {
    return error;
  }
};

export const fetchRealtimeVisitorCount = ({ queryKey }) => {
  const url = LBA_API.realtimeVisitorCount;
  const sensorId = queryKey[1];

  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    auth: { username: 'adminrealtime', password: 'Vdm1n_R3@LT1me&728!' },
  };
  const params = new URLSearchParams({
    timezone: 'Asia/Seoul',
  });
  return axios.get(`${url}${sensorId}?${params}`, config);
};

export const fetchLoginApi = ({ username, password }) => {
  const url = LBA_API.login;
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
    auth: { username, password },
  };
  return axios.get(url, config);
};

export const fetchOpenWeatherApi = () => {
  const url = process.env.REACT_APP_OPEN_WEATHER_URL;
  const params = new URLSearchParams({
    appid: process.env.REACT_APP_OPEN_WEATHER_API_KEY,
    lat: coordinates?.[0],
    lon: coordinates?.[1],
    // q: process.env.REACT_APP_SITE_CITY,
    lang: 'kr',
    units: 'metric',
  });
  return fetch(`${url}?${params}`).then((res) => res.json());
};
