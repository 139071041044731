/* eslint-disable no-extra-boolean-cast */
import React, { useEffect, useMemo, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useQuery } from 'react-query';
import { fetchOpenWeatherApi } from 'lib/api';

import styled from 'styled-components';
import chroma from 'chroma-js';
import symbolSvg from 'assets/images/symbol.svg';

import { CONGESTION_LEVELS } from 'lib/constants';
import { COLORS } from 'lib/theme';
import { useAuth } from '../lib/contexts/auth';

const InformationBoardComponent = styled.div`
  float: right;
  height: 100%;

  & div.container {
    height: 100%;
    width: 100%;
    position: relative;
    padding-right: 0;

    & div.title {
      color: ${COLORS.light};
      font-size: 25px;
      font-weight: bold;
      margin-bottom: 20px;
      margin-top: 1rem;
      text-align: center;
    }
  }

  & div.weather {
    background-color: ${COLORS.transparentWhite};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    padding: 1rem;
    margin-bottom: 15px;

    & div.descDiv {
      font-size: 22px;
      font-weight: 550;
      text-align: right;
      margin-right: 0.7rem;
      color: ${chroma('gray').darken(1.6)};
    }
  }

  & div.legends {
    background-color: ${COLORS.transparentWhite};
    border-radius: 5px;
    margin-bottom: 15px;
    padding: 1rem;
    line-height: 1.5;
  }

  & i.owi {
    color: ${COLORS.primary};
  }

  & div.symbol {
    position: absolute;
    width: 100%;
    bottom: 23px;
    text-align: center;
  }
`;

export default function InformationBoard() {
  const { logout } = useAuth();

  const [weatherInfo, setWeatherInfo] = useState({});

  const legends = useMemo(
    () => [
      CONGESTION_LEVELS.sparse,
      CONGESTION_LEVELS.normal,
      CONGESTION_LEVELS.crowded,
      CONGESTION_LEVELS.congested,
    ],
    []
  );

  const weatherQuery = useQuery({
    queryKey: 'openWeather',
    queryFn: fetchOpenWeatherApi,
    refetchInterval: 3600000, // refetch every hour
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (weatherQuery.data) {
      const { data } = weatherQuery;
      const {
        main: { temp },
        weather: [weather],
      } = data;
      setWeatherInfo({
        description: weather?.description,
        icon: weather?.icon,
        temperature: temp,
      });
    }
  }, [weatherQuery.data]);

  return (
    <InformationBoardComponent>
      <div className="container">
        <div className="title">
          {process.env.REACT_APP_NAME}
          <hr />
        </div>
        {weatherQuery.isSuccess && (
          <div className="weather">
            <i className={`owi owi-fw owi-5x owi-${weatherInfo.icon}`} />
            <div className="descDiv">
              <div className="text-capitalize">{weatherInfo.description}</div>
              <div style={{ fontSize: '22px', paddingTop: '0.5rem' }}>
                {Math.round(weatherInfo.temperature)}°C
              </div>
            </div>
          </div>
        )}
        <div className="legends">
          {legends.map((legend) => (
            <div key={legend.name}>
              <FontAwesomeIcon icon="circle" color={legend.fill} size="xs" />{' '}
              {legend.label}
            </div>
          ))}
        </div>

        <div className="symbol">
          <img src={symbolSvg} height={330} alt="Symbol" />
        </div>
      </div>
    </InformationBoardComponent>
  );
}
