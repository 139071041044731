import React from 'react';
import { ClockFill } from 'react-bootstrap-icons';

export default function Loader() {
  return (
    <div
      className="position-fixed d-flex justify-content-center align-items-center"
      style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', inset: 0, zIndex: 1000 }}>
      <ClockFill size={80} className="text-white spin" />
    </div>
  );
}
