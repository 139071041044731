import React from 'react';

import { PieChart } from 'react-minimal-pie-chart';

import { COLORS } from 'lib/theme';

export default function GaugeChart({ data, options }) {
  return (
    <PieChart
      // animate
      data={data}
      startAngle={135}
      endAngle={0}
      lengthAngle={270}
      lineWidth={25}
      radius={34}
      background={COLORS.gray}
      {...options}>
      <text
        x={15}
        y="78%"
        textAnchor="middle"
        fill={COLORS.light}
        style={{ fontSize: 5 }}>
        여유
        {/* Sparse */}
      </text>
      <text
        x={89}
        y="80%"
        textAnchor="middle"
        fill={COLORS.light}
        style={{ fontSize: 5 }}>
        {/* Congested */}
        매우 혼잡
        {/* <tspan x={83} dy={6}></tspan> */}
      </text>
    </PieChart>
  );
}
