export const sensors = {
  3850: {
    id: 3850,
    area: 50,
    name: '정문 매표소',
    index: 1,
    lat: 35.321747,
    lng: 127.004104,
  },
};
