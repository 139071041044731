/* eslint-disable no-extra-boolean-cast */
/* eslint-disable arrow-body-style */
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import axios from 'axios';

import { LBA_API } from '../constants';
import { getErrorMessage } from '../utils';

const AuthContext = createContext({
  authenticated: false,
  errorMessage: null,
  user: {},
  login: () => {},
  logout: () => {},
});

export const useAuth = () => useContext(AuthContext);
export const AuthConsumer = AuthContext.Consumer;

export default function AuthProvider({ children }) {
  const [authenticated, setAuthenticated] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [loading, setLoading] = useState(false);

  const login = async ({ username, password }) => {
    setLoading(true);
    try {
      const url = LBA_API.login;

      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
        auth: { username, password },
      };
      const response = await axios.get(url, config);
      if (response?.data) {
        setAuthenticated(true);
        localStorage.setItem('loggedIn', true);
      }
      setLoading(false);
    } catch (err) {
      setErrorMessage(getErrorMessage(err));
      setLoading(false);
    }
  };

  const logout = () => {
    localStorage.clear();
    setAuthenticated(false);
  };

  useEffect(() => {
    if (localStorage.getItem('loggedIn')) {
      setAuthenticated(true);
    }
  }, []);

  useEffect(async () => {
    if (loading) {
      setErrorMessage(null);
    }
  }, [loading]);

  const contextValues = useMemo(
    () => ({ authenticated, errorMessage, loading, login, logout }),
    [authenticated, errorMessage, loading]
  );

  return (
    <AuthContext.Provider value={contextValues}>
      {children}
    </AuthContext.Provider>
  );
}
