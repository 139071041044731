/* eslint-disable react/self-closing-comp */
import './polyfills';

import React from 'react';
import ReactDOM from 'react-dom';

import { QueryClient, QueryClientProvider } from 'react-query';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import AuthProvider from './lib/contexts/auth';

import App from './App';
import reportWebVitals from './reportWebVitals';

import 'assets/css/index.css';
import 'assets/css/owi.css';

import 'leaflet/dist/leaflet.css';
// eslint-disable-next-line import/extensions
import 'leaflet/dist/leaflet.js';
import '@brainhubeu/react-carousel/lib/style.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const queryClient = new QueryClient();

library.add(fas);

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
