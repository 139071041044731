export const COLORS = {
  accent: '#C9745E',
  dark: '#2F4F4F',
  gray: '#CECED2',
  light: '#F6F7FB',
  lightBlue: '#E2EFFD',
  primary: '#5FC99F',
  transparentWhite: 'rgba(255, 255, 255, 0.95)',
  transparentWhite50: 'rgba(255, 255, 255, 0.5)',

  transparentWhite30: 'rgba(255, 255, 255, 0.3)',
  transparentWhite20: 'rgba(255, 255, 255, 0.2)',
  transparentWhite10: 'rgba(255, 255, 255, 0.04)',
};
