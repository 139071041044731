import { CONGESTION_LEVELS, SITE_NAME } from 'lib/constants';
import { Col, Row } from 'react-bootstrap';
import React, { useMemo } from 'react';
import { useQueries } from 'react-query';
import styled from 'styled-components';

import { COLORS } from 'lib/theme';
import Container from 'components/Container';
import GaugeChart from 'components/GaugeChart';
import InformationBoard from 'components/InformationBoard';
import { getCongestionLevel } from 'lib/utils';
import { sensors } from 'lib/config';
import { DateTime } from 'luxon';
import chroma from 'chroma-js';

import SensorsMap from './SensorsMap';
import { fetchRealtimeVisitorCount } from '../lib/api';
import Wrapper from '../components/Wrapper';

const ChartsContainer = styled.div`
  position: absolute;
  width: 74%;
  height: 100%;
  display: inline-flex;

  & div.rowDiv {
    color: ${COLORS.light};
    display: grid;
    grid-template-columns: 0% 50%;
  }

  & div.mapDiv {
    width: 65%;
    height: 100%;
    padding: 0;
  }

  & div.sensorInfoDiv {
    padding-top: 1rem;
    padding-left: 1rem;

  & div.chart {
    border: 1px ${COLORS.light} solid;
    background: ${COLORS.transparentWhite10};
    border-radius: 5px;
    margin: 0.3rem;
    // height: 97%;
  }

  & div.gaugeChartDiv {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  & div.name {
    color: ${COLORS.light};
    font-size: 16px;
    font-weight: bold;
    margin-top: 5px;
    position: relative;
    text-align: center;
    & small {
      position: absolute;
      right: 5px;
      font-size: 12px;
      font-weight: normal;
    }
  }
`;

export default function KioskMap() {
  const SENSORS = useMemo(
    () => Object.values(sensors).sort((a, b) => a.index - b.index),
    []
  );

  const results = useQueries(
    SENSORS.map((sensor) => ({
      queryKey: ['sensor', sensor.id],
      queryFn: fetchRealtimeVisitorCount,
      refetchInterval: 60000,
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: false,
    }))
  );

  const resultData = useMemo(
    () =>
      results.map((result, i) => {
        const sensor = SENSORS[i];
        const data = result?.data?.data || {};
        const count = data?.count || 0;
        const time = data ? DateTime.fromISO(data.time).toFormat('t') : 0;

        const max = sensor.area / CONGESTION_LEVELS.congested.distance;
        const level = getCongestionLevel(count, sensor.area);
        return {
          id: sensor.id,
          name: sensor.name,
          area: sensor.area,
          lat: sensor.latitude,
          lng: sensor.longitude,
          count,
          time,
          level,
          max,
        };
      }),
    [results]
  );

  return (
    <Wrapper>
      <Container>
        <ChartsContainer>
          <div className="mapDiv">
            <SensorsMap props={results} />
          </div>
          <div className="sensorInfoDiv">
            {resultData.map((data) => {
              const { id, area, name, count, level, max } = data;
              return (
                <>
                  <div key={id + name} className="chart">
                    <div className="name pt-2">
                      {name}
                      <small>
                        {area} m<sup>2</sup>
                      </small>
                    </div>
                    <hr className="text-light text-center" />
                    <div className="gaugeChartDiv">
                      <GaugeChart
                        data={
                          count
                            ? [
                                {
                                  title: name,
                                  value: count > max ? max : count,
                                  color: level.fill,
                                },
                              ]
                            : [
                                {
                                  title: name,
                                  value: 0,
                                },
                              ]
                        }
                        options={{
                          label: () => level.label,
                          labelPosition: 0,
                          labelStyle: {
                            fontSize: '10px',
                            fontFamily: 'sans-serif',
                            fill: level.fill,
                          },
                          totalValue: max,
                        }}
                        label={{
                          fill: level.fill,
                          value: level.label,
                        }}
                      />
                    </div>
                    {/* <div className="text-center pt-0 font-weight-bold">
                    <span
                      style={{
                        fontSize: 34,
                        fontWeight: 600,
                        color: chroma(level.fill),
                      }}>
                      {data.count}
                    </span>
                  </div> */}
                    <div
                      style={{
                        display: 'block',
                        fontSize: '9px',
                        textAlign: 'right',
                        marginTop: '0.8rem',
                        marginRight: '0.3rem',
                        color: chroma('white').alpha(0.6),
                      }}>
                      <span>{data.time}</span>
                    </div>
                  </div>
                  <hr />
                </>
              );
            })}
          </div>
        </ChartsContainer>
        <InformationBoard />
      </Container>
    </Wrapper>
  );
}
