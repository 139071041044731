import styled from 'styled-components';

import { SCREEN } from 'lib/constants';
import { COLORS } from 'lib/theme';
import chroma from 'chroma-js';

export default styled.div`
  background-color: ${chroma(COLORS.dark)};
  padding-right: 1rem;
  height: ${SCREEN.height}px;
  width: ${SCREEN.width}px;
  overflow: hidden;
  position: relative;
`;
