/* eslint-disable no-loop-func */
/* eslint-disable no-plusplus */
import { CAROUSEL, CONGESTION_LEVELS } from 'lib/constants';
import turfCenter from '@turf/center';
import { points } from '@turf/helpers';

export const getCongestionLevel = (count, area) => {
  if (count === 0 || count <= area / CONGESTION_LEVELS.sparse.distance)
    return CONGESTION_LEVELS.sparse;
  if (count <= area / CONGESTION_LEVELS.normal.distance)
    return CONGESTION_LEVELS.normal;
  if (count <= area / CONGESTION_LEVELS.crowded.distance)
    return CONGESTION_LEVELS.crowded;

  return CONGESTION_LEVELS.congested; // CONGESTION_LEVELS.congested.distance
};

export const getRandomPosNegNumber = (max) =>
  Math.random() * max * (Math.random() - 0.5) * 2;

export const getTotalSlides = (arrLength) => {
  const { itemsPerSlide } = CAROUSEL;
  return itemsPerSlide > 0 ? Math.ceil(arrLength / itemsPerSlide) : 0;
};

export const getCarouselData = (sensorData) => {
  const { itemsPerSlide } = CAROUSEL;
  const resultCarouselArr = [];
  let tmp = 0;
  const totalSlides = getTotalSlides(sensorData.length);

  for (let i = 0; i < totalSlides; i++) {
    resultCarouselArr.push(
      sensorData.filter(
        (_data, indx) => indx >= tmp && indx < tmp + itemsPerSlide
      )
    );
    if (resultCarouselArr.length < totalSlides) {
      tmp = (i + 1) * itemsPerSlide;
    }
  }
  return resultCarouselArr;
};

export const getMainChartsData = (sensorIds, arr) => {
  const chartsData = sensorIds.map((data) =>
    arr.find((item) => item.id === data)
  );
  return chartsData;
};

export const getErrorMessage = (error) => {
  const message = error?.response?.data?.detail || 'SERVER ERROR';

  return message;
};

export const getMapCenter = (sensors) => {
  let centerPoint = {
    lat: 0,
    lng: 0,
  };
  const coordinates = sensors.map((sensor) => {
    const x = sensor.lat || 0;
    const y = sensor.lng || 0;
    return [x, y];
  });

  const turfPoints = points(coordinates);
  const center = turfCenter(turfPoints);

  centerPoint = {
    lat: center.geometry.coordinates[0] || 0,
    lng: center.geometry.coordinates[1] || 0,
  };
  return centerPoint;
};
